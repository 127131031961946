// import GoPlay from 'goplay-ws';
import { GoPlay } from "goplay-ws/src/pkg.pb";
import { Rugbull } from "../../network/generated/pkg.pb";
import eventEmitter from "src/utils/eventEmitter";
import goplay from "goplay-ws";

interface Login_LoginResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.SCLogin;
}

interface Bet_BuyResult {
  status: GoPlay.Core.Protocols.Status;
  data: any;
}

interface Bet_SellResult {
  status: GoPlay.Core.Protocols.Status;
  data: any;
}

interface Bet_SetBetResult {
  status: GoPlay.Core.Protocols.Status;
  data: any;
}

interface Bet_CancelResult {
  status: GoPlay.Core.Protocols.Status;
  data: any;
}

interface Friend_GetFriendResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.SCFriend;
}
interface Friend_GetListResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.SCFriendList;
}

interface Invite_InviteByResult {
  status: GoPlay.Core.Protocols.Status;
  data: any;
}

interface Coin_NewUserRewardResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.SCNewUserReward;
}

interface Bet_SetAutoBuyResult {
  status: GoPlay.Core.Protocols.Status;
  data: any;
}

interface Game_GetMultiplierWinsResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.SCTopMultiplierList;
}

interface Game_GetHugeWinsResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.SCTopBetList;
}

interface Game_GetBiggestWinsResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.SCTopBetList;
}

interface Game_GetPrevGameBetsResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.PushGameBets;
}

interface BetProcessor2_BuyResult {
  status: GoPlay.Core.Protocols.Status;
  data: any;
}
interface BetProcessor2_SellResult {
  status: GoPlay.Core.Protocols.Status;
  data: any;
}
interface BetProcessor2_SetBetResult {
  status: GoPlay.Core.Protocols.Status;
  data: any;
}
interface Bet_GetBetHistoryResult {
  status: GoPlay.Core.Protocols.Status;
  data: any;
}

interface Coin_GetDepositInfoResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.SCDespositInfo;
}

interface Coin_OrderCancelResult {
  status: GoPlay.Core.Protocols.Status;
  data: any;
}
interface Coin_CreateOrderResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.SCOrderInfo;
}
interface Coin_GetExistOrderResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.SCOrderInfo;
}

interface Task_GetAchieveTasksResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.PushTask;
}
interface Task_ClaimAchieveResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.SCClaimReward;
}
interface Wallet_GetWalletWithdrawsResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.PushWalletWithDraw;
}
interface Wallet_RequestWithDrawResult {
  status: GoPlay.Core.Protocols.Status;
  data: any;
}
interface Wallet_SetWalletResult {
  status: GoPlay.Core.Protocols.Status;
}
interface Task_GetForceTaskResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.SCForceTask;
}
interface Wallet_GetWithdrawRecordsResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.SCWithdrawRecord;
}
interface Game_GetRoundInfoResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.SCGetRoundInfo;
}
interface Coin_SwitchCoinResult {
  status: GoPlay.Core.Protocols.Status;
  data: any;
}
interface Task_ClaimFollowsResult {
  status: GoPlay.Core.Protocols.Status;
  data: any;
}
interface Task_ClaimRewardCashResult {
  status: GoPlay.Core.Protocols.Status;
  data: any;
}
interface Task_DoTaskResult {
  status: GoPlay.Core.Protocols.Status;
  data: any;
}
interface Coin_GetCoinInfosResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.SCCoinInfo;
}
interface User_UpdateLanguageResult {
  status: GoPlay.Core.Protocols.Status;
  data: any;
}

interface Trade_GetInfoResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.SCGetTrade;
}

interface Login_LoginWithCodeResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.SCLogin;
}

interface Login_GoogleLoginResult {
  status: GoPlay.Core.Protocols.Status;
  data: Rugbull.Protocols.CSLoginWithCode;
}

export class UserState {
  public userInfo?: Rugbull.Protocols.SCLogin;
}

class DataViewTools {
  PBString: any;
  StatusCode: any;

  constructor() {
    // this.PBString = window.GoPlay.Core.Protocols.PbString;
    // this.StatusCode = window.GoPlay.Core.Protocols.StatusCode;
  }

  async Login_Login(arg: Rugbull.Protocols.CSLogin) {
    await window.goplay.connect();
    let res = await window.goplay.request(
      "login.login",
      arg,
      Rugbull.Protocols.SCLogin
    );

    if (res.status.Code !== GoPlay.Core.Protocols.StatusCode.Success) {
      eventEmitter.emit("login_failed");
    }

    window.UserData = res.data;
    return res;
  }


  connect = async () => {
    const ConnectUrl = process.env.REACT_APP_BACKEND_URL;
    return await window.goplay.connect(ConnectUrl);
  };

  disconnect = async () => {
    return await window.goplay.disconnect();
  };

  reconnect = async () => {
    //TODO: FIX-ME 这里应该先判断连接状态，再判断登录状态，如果连接状态是断开的，就重新连接，如果登录状态是未登录的，就重新登录

    

    var ok = true;
    if (!window.goplay.isConnected) {
      ok = await this.connect();
    }

    if (!ok) return false;

    if (!window?.UserData) {
      window && (window.UserBets = []); //清空UserBets信息
      eventEmitter.emit("onType", { Reset: true || [] }); //清空UserBets信息

        if(window.Telegram?.WebApp?.initData){
          const params = 
      !window.Telegram?.WebApp?.initData && process.env.NODE_ENV === "development"
        ? {
            UtmSource: "",
            initData:
              "query_id=AAHSoXxhAAAAANKhfGFkZY6G&user=%7B%22id%22%3A1635557842%2C%22first_name%22%3A%22Wom%20Metro%2030%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22wometro%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1727226180&hash=30a71e99ce995f57ddbb64bbcd5613693a1d7fe7c74edc69923b2cb9906e2f20",
            User: {
              id: 6627109917,
              first_name: "Wom Metro 30",
              last_name: "",
              username: "wometro",
              language_code: "en",
              allows_write_to_pm: true,
              is_premium: false,
            },
          }:
        {
            UtmSource: "",
            initData: window.Telegram.WebApp.initData,
            User: window.Telegram.WebApp.initDataUnsafe.user,
          };

        let arg = Rugbull.Protocols.CSLogin.create({
          UtmSource: "",
          InitData: params.initData,
          User: {
            Username: params.User.username,
            IsBot: params.User.is_bot,
            Id: params.User.id,
            FirstName: params.User.first_name,
            LastName: params.User.last_name,
            LanguageCode: params.User.language_code,
            IsPremium: params.User.is_premium,
            AddedToAttachmentMenu: params.User.added_to_attachment_menu,
            AllowsWriteToPm: params.User.allows_write_to_pm,
            PhotoUrl: params.User.photo_url,
          },
        });


        let { status, data } = await this.Login_Login(arg);
        ok = status.Code === GoPlay.Core.Protocols.StatusCode.Success;
        }
    }
    return ok;
  };

  async Task_ClaimAchieve(
    arg: Rugbull.Protocols.CSClaimRewardAchieve
  ): Promise<Task_ClaimAchieveResult> {
    await window.goplay.connect();
    return window.goplay.request(
      "task.claim.achieve",
      arg,
      Rugbull.Protocols.SCClaimReward
    );
  }

  async BetProcessor2_Buy(
    arg: Rugbull.Protocols.CSBetSet
  ): Promise<BetProcessor2_BuyResult> {
    await window.goplay.connect();
    return window.goplay.request("bet2.buy", arg);
  }

  async BetProcessor2_Sell(
    arg: Rugbull.Protocols.CSBetSell
  ): Promise<BetProcessor2_SellResult> {
    await window.goplay.connect();
    return window.goplay.request("bet2.sell", arg);
  }

  async BetProcessor2_SetBet(
    arg: Rugbull.Protocols.CSBetSet
  ): Promise<BetProcessor2_SetBetResult> {
    await window.goplay.connect();
    return window.goplay.request("bet2.set.auto", arg);
  }

  async Bet_Buy(arg: Rugbull.Protocols.CSBetBuy): Promise<Bet_BuyResult> {
    await window.goplay.connect();
    return window.goplay.request("bet.buy", arg);
  }

  async Bet_Sell(arg: Rugbull.Protocols.CSBetSell): Promise<Bet_SellResult> {
    await window.goplay.connect();
    return window.goplay.request("bet.sell", arg);
  }

  async Bet_SetAutoBuy(
    arg: Rugbull.Protocols.CSBetSet
  ): Promise<Bet_SetAutoBuyResult> {
    await window.goplay.connect();
    return window.goplay.request("bet.set.auto.buy", arg);
  }

  async Bet_SetBet(
    arg: Rugbull.Protocols.UserBetModel
  ): Promise<Bet_SetBetResult> {
    await window.goplay.connect();
    return window.goplay.request("bet.set.bet", arg);
  }

  async Bet_Cancel(
    arg: Rugbull.Protocols.CSBetCanncel
  ): Promise<Bet_CancelResult> {
    await window.goplay.connect();
    return window.goplay.request("bet.cancel", arg);
  }

  async Friend_GetFriend(
    arg: Rugbull.Protocols.CSFriend
  ): Promise<Friend_GetFriendResult> {
    await window.goplay.connect();
    return window.goplay.request("friend.get", arg, Rugbull.Protocols.SCFriend);
  }

  async Friend_GetList(
    arg: Rugbull.Protocols.CSFriendList
  ): Promise<Friend_GetListResult> {
    await window.goplay.connect();
    return window.goplay.request(
      "friend.get.list",
      arg,
      Rugbull.Protocols.SCFriendList
    );
  }

  async Invite_InviteBy(
    arg: Rugbull.Protocols.CSInvitedBy
  ): Promise<Invite_InviteByResult> {
    await window.goplay.connect();
    return window.goplay.request("invite.by", arg);
  }

  async Coin_NewUserReward(): Promise<Coin_NewUserRewardResult> {
    await window.goplay.connect();
    return window.goplay.request(
      "coin.new.reward",
      null,
      Rugbull.Protocols.SCNewUserReward
    );
  }

  async Game_GetMultiplierWins(
    arg: Rugbull.Protocols.CSTopList
  ): Promise<Game_GetMultiplierWinsResult> {
    await window.goplay.connect();
    return window.goplay.request(
      "game.multiplier",
      arg,
      Rugbull.Protocols.SCTopMultiplierList
    );
  }

  async Game_GetHugeWins(
    arg: Rugbull.Protocols.CSTopList
  ): Promise<Game_GetHugeWinsResult> {
    await window.goplay.connect();
    return window.goplay.request(
      "game.huge.wins",
      arg,
      Rugbull.Protocols.SCTopBetList
    );
  }

  async Game_GetBiggestWins(
    arg: Rugbull.Protocols.CSTopList
  ): Promise<Game_GetBiggestWinsResult> {
    await window.goplay.connect();
    return window.goplay.request(
      "game.biggest.wins",
      arg,
      Rugbull.Protocols.SCTopBetList
    );
  }

  async Game_GetPrevGameBets(): Promise<Game_GetPrevGameBetsResult> {
    await window.goplay.connect();
    return window.goplay.request(
      "game.prev.infos",
      null,
      Rugbull.Protocols.PushGameBets
    );
  }

  async Bet_GetBetHistory(): Promise<Bet_GetBetHistoryResult> {
    await window.goplay.connect();
    return window.goplay.request(
      "bet.get.bet.history",
      null,
      Rugbull.Protocols.PushBetHistory
    );
  }

  async Coin_GetDepositInfo(): Promise<Coin_GetDepositInfoResult> {
    await window.goplay.connect();
    return window.goplay.request(
      "coin.deposit.info",
      null,
      Rugbull.Protocols.SCDespositInfo
    );
  }

  async Coin_OrderCancel(
    arg: Rugbull.Protocols.CSOrderCancel
  ): Promise<Coin_OrderCancelResult> {
    await window.goplay.connect();
    return window.goplay.request("coin.order.cancel", arg);
  }

  async Coin_CreateOrder(
    arg: Rugbull.Protocols.CSOrderInfo
  ): Promise<Coin_CreateOrderResult> {
    await window.goplay.connect();
    return window.goplay.request(
      "coin.order.create",
      arg,
      Rugbull.Protocols.SCOrderInfo
    );
  }

  async Coin_GetExistOrder(
    arg: Rugbull.Protocols.CSOrderInfo
  ): Promise<Coin_GetExistOrderResult> {
    await window.goplay.connect();
    return window.goplay.request(
      "coin.order.exist.one",
      arg,
      Rugbull.Protocols.SCOrderInfo
    );
  }

  async Task_GetAchieveTasks(): Promise<Task_GetAchieveTasksResult> {
    await window.goplay.connect();
    return window.goplay.request(
      "task.get.achieve.tasks",
      null,
      Rugbull.Protocols.PushTask
    );
  }

  async Wallet_GetWalletWithdraws(): Promise<Wallet_GetWalletWithdrawsResult> {
    await window.goplay.connect();
    return window.goplay.request(
      "wallet.get.wallet.withdraws",
      null,
      Rugbull.Protocols.PushWalletWithDraw
    );
  }

  async Wallet_RequestWithDraw(
    arg: Rugbull.Protocols.CSWalletWithDraw
  ): Promise<Wallet_RequestWithDrawResult> {
    await window.goplay.connect();
    return window.goplay.request("wallet.req.withdraw", arg);
  }

  async Wallet_SetWallet(
    arg: Rugbull.Protocols.CSSetWallet
  ): Promise<Wallet_SetWalletResult> {
    await window.goplay.connect();
    return window.goplay.request("wallet.set.wallet", arg);
  }

  async Wallet_GetWithdrawRecords(
    arg: Rugbull.Protocols.CSWithdrawRecord
  ): Promise<Wallet_GetWithdrawRecordsResult> {
    await window.goplay.connect();
    return window.goplay.request(
      "wallet.withdraw.record",
      arg,
      Rugbull.Protocols.SCWithdrawRecord
    );
  }

  async Task_GetForceTask(): Promise<Task_GetForceTaskResult> {
    await window.goplay.connect();
    return window.goplay.request(
      "task.get.force.task",
      null,
      Rugbull.Protocols.SCForceTask
    );
  }

  async Game_GetRoundInfo(
    arg: Rugbull.Protocols.CSGetRoundInfo
  ): Promise<Game_GetRoundInfoResult> {
    await window.goplay.connect();
    return window.goplay.request(
      "game.round.info",
      arg,
      Rugbull.Protocols.SCGetRoundInfo
    );
  }

  async Coin_SwitchCoin(
    arg: Rugbull.Protocols.CSSwitchCoin
  ): Promise<Coin_SwitchCoinResult> {
    await window.goplay.connect();
    return window.goplay.request("coin.switch", arg);
  }
  async Coin_GetCoinInfos(): Promise<Coin_GetCoinInfosResult> {
    await window.goplay.connect();
    return window.goplay.request(
      "coin.coin.infos",
      null,
      Rugbull.Protocols.SCCoinInfo
    );
  }
  async User_UpdateLanguage(
    arg: Rugbull.Protocols.CSUpdateLanguage
  ): Promise<User_UpdateLanguageResult> {
    await window.goplay.connect();
    return window.goplay.request("user.update.language", arg);
  }

  async Task_ClaimFollows(
    arg: Rugbull.Protocols.CSClaimFollows
  ): Promise<Task_ClaimFollowsResult> {
    await window.goplay.connect();
    return window.goplay.request("task.claim.follows", arg);
  }

  async Task_ClaimRewardCash(
    arg: Rugbull.Protocols.CSDoTask
  ): Promise<Task_ClaimRewardCashResult> {
    await window.goplay.connect();
    return window.goplay.request("task.claim.reward.cash", arg);
  }

  async Task_DoTask(
    arg: Rugbull.Protocols.CSDoTask
  ): Promise<Task_DoTaskResult> {
    await window.goplay.connect();
    return window.goplay.request("task.do.task", arg);
  }

  async Trade_GetInfo (arg: Rugbull.Protocols.CSGetTrade): Promise<Trade_GetInfoResult> {
    await window.goplay.connect();
    return window.goplay.request("trade.info", arg, Rugbull.Protocols.SCGetTrade);
  }

  async Login_LoginWithCode (arg: Rugbull.Protocols.CSLoginWithCode): Promise<Login_LoginWithCodeResult> {
    await window.goplay.connect();
    let res = await window.goplay.request("login.code", arg, Rugbull.Protocols.SCLogin);

    if (res.status.Code !== GoPlay.Core.Protocols.StatusCode.Success) {
      eventEmitter.emit("login_failed");
    }
  
    window.UserData = res.data;

    return res;
  }

  async Login_GoogleLogin (arg: Rugbull.Protocols.CSLoginByGoogle): Promise<Login_GoogleLoginResult> {
    await window.goplay.connect();
    return window.goplay.request("login.google.login", arg, Rugbull.Protocols.CSLoginWithCode);
  }

  getOrderBookData = async () => {
    // let _data = this.PBString.create({"Value": '333'});
    // window.goplay.notify("test.notify", _data);
    // var {status, data} = await window.goplay.request("test.echo", _data, this.PBString);
  };

  getHistoryData = () => {};

  getHugeWins = () => {};

  getBiggestWins = () => {};
}

export default DataViewTools;
