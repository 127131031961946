import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "./index.scss";
import classNames from "classnames";
import { useLogin } from "src/App";
import {  OpenLink, OpenLocalLink } from "src/utils";
import eventEmitter from "src/utils/eventEmitter";
import { useNotification } from "rc-notification";
import CopyToClipboard from "react-copy-to-clipboard";

interface Props {
  limitMaximum: number;
}

interface Props1 {
  shareMsg: string;
  handleCopy1?: Function;
}

const ShareLinkGroup = [
  {
    icon: "icon-link-btn",
    type: "link",
  },
  {
    icon: "icon-twitter-btn",
    type: "twitter",
  },
  {
    icon: "icon-ins-btn",
    type: "insgram",
  },
  {
    icon: "icon-facebook-btn",
    type: "facebook",
  },
  {
    icon: "icon-phone-btn",
    type: "phone",
  },
];



const ShareBtn: React.FC<Props1> = ({shareMsg,handleCopy1}) => {
  const [{ open, close }, holder] = useNotification();
  const { t } = useTranslation();
  const { handlecopy} = useLogin();


  const onShareBtnClick = (v) => {
    // navigator.clipboard.writeText(shareMsg);
    console.log("shareMsg",shareMsg)
    if (v.type === "link") {
      console.log('handlecopy')
      handlecopy ? handlecopy() : handleCopy1();
      return;
    }

    let url = "";

    if (v.type === "twitter") {
      let msg = encodeURI(shareMsg).replace("+", "%20");
      url = `https://twitter.com/intent/tweet?text=${msg}`;
    }

    if (v.type === "facebook") {
      url = `https://www.facebook.com/sharer/sharer.php?u=${shareMsg}`;
    }

    if (v.type === "phone") {
      url = `https://wa.me/?text=${shareMsg}`;
    }

    if (v.type === "insgram") {
      url = `https://www.instagram.com/?text=${shareMsg}`;
    }

    OpenLocalLink(url);
  };

  return (
    <div className="share-btn-group">
        {ShareLinkGroup.map((v) => {
          return (
          
            <CopyToClipboard text={shareMsg} >
            
              <div
                className={classNames("share-btn-item", v.icon)}
                onClick={() => onShareBtnClick(v)}
              >
                
              </div>
            </CopyToClipboard>

          );
        })}
    </div>
  );
};

const ShareDrawer: React.FC<Props> = ({ limitMaximum }) => {
  const [{ open, close }, holder] = useNotification();
  const { handlecopy, loginData ,GetShareUrl,} = useLogin();
  const { t } = useTranslation();

  let link =  GetShareUrl(loginData.InviteCode);


  const GoTOPlay = () => {
    eventEmitter.emit("gotopaly", null);
    console.log("go to play");
  };

  return (
    <div className="share-content-drawer">
      <button type="button" className="btn play" onClick={GoTOPlay}>
        {t("ShareDrawer.Trade")}
      </button>
      <span className="No-withdraw-limitma">
        {t("ShareDrawer.No_withdraw_limitma")}&nbsp;
        <span className="text-style-1">
          x{loginData.RewardInfo.MaxMultiplier}
        </span>
      </span>
      <button
        type="button"
        className="btn invite"
        onClick={() => {
          let link = GetShareUrl(loginData.InviteCode)
          OpenLink(link.link);
        }}
      >
        {t("ShareDrawer.Invite")}
      </button>
      <span className="No-withdraw-limitma invite-btn-tips">
        {t("ShareDrawer.invite_btn_tips", {
          InvitePerReward: loginData.RewardInfo.InvitePerReward,
        })}
      </span>

      <div className="share-link-content">
        <div className="share-top-icon">
            <span className="text">{t("ShareDrawer.ShareTo")}</span>
        </div>
        <ShareBtn shareMsg={GetShareUrl(loginData.InviteCode).text} />
      </div>
      
    </div>
  );
};

export default ShareDrawer;

export { ShareBtn };
