import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import CloseIcon from "src/components/SvgIcon/CloseIcon";
import "./index.scss";
import { useTranslation } from "react-i18next";
import DataViewTools from "src/views/DataView/DataViewTools";
import { GoPlay } from "goplay-ws/src/pkg.pb";
import { Rugbull } from "src/network/generated/pkg.pb";
import goplay from "goplay-ws";
import { GetCoinName, GetCoinPic, Show } from "src/utils";
import { number } from "echarts";
import InsBalanceModal from "src/views/Trade/components/InsBalanceModal";
import { useNavigate } from "react-router-dom";

export const Radio = ({ checked }) => {
  if (checked) {
    return (
      <svg
        className="svg-radio"
        width="auto"
        height="auto"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fill-rule="evenodd">
          <circle stroke="#E8BA41" cx="6" cy="6" r="5.5" />
          <circle fill="#E8BA41" cx="6" cy="6" r="3" />
        </g>
      </svg>
    );
  } else {
    return (
      <svg
        className="svg-radio"
        width="auto"
        height="auto"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fill-rule="evenodd">
          <circle stroke="#7A8088" cx="6" cy="6" r="5.5" />
          <circle fill="#7A8088" cx="6" cy="6" r="3" />
        </g>
      </svg>
    );
  }
};

const CurrencyChose = ({ visible, onClose, current, dataSource, onSelect }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectValues, setSelectValues] = useState({
    Coin: current.Coin,
    Type: current.Type,
  });
  const tools = new DataViewTools();
  const [modalVisible, setModalVisible] = useState({
    ProvablyModal: false,
    JoinChannelModal: false,
    InsBalanceModal: false,
    HowToEarnModal: false,
    RoundModal: false,
  });
  const Coin_SwitchCoin = async (CoinType) => {
    let { status } = await tools.Coin_SwitchCoin(
      Rugbull.Protocols.CSSwitchCoin.create({
        Type: CoinType,
      })
    );
    if (status.Code !== GoPlay.Core.Protocols.StatusCode.Success) {
      console.log("Game_GetRoundInfo Error");
      Show(status);
      return;
    }
  };

  useEffect(() => {
    setSelectValues(current);
  }, [current]);

  const _onSelect = (v) => {
    setSelectValues({
      Coin: v.Coin,
      Type: v.Type,
    });
  };

  const onConfirm = () => {
    // const Coinname = GetCoinPic(selectValues.Type);
    if (onSelect && current.Type != selectValues.Type) {
      if (selectValues.Coin <= 0 && selectValues.Type !== Rugbull.Protocols.CoinType.CoinType_Coin) {
        setModalVisible({
          ...modalVisible,
          InsBalanceModal: true,
        });
        setSelectValues(current);
        return;
      }
      Coin_SwitchCoin(selectValues.Type);
      onSelect({
        Type: selectValues.Type,
        Coin: selectValues.Coin,
      });
      return;
    }
    onClose();
  };

  return (
    <div
      className={classNames("rug-chose-currency", {
        visible: visible,
      })}
    >
      {/* Bull1016-06 弹框 */}
      <InsBalanceModal
        open={modalVisible.InsBalanceModal}
        onOk={() => {
          setModalVisible({
            ...modalVisible,
            InsBalanceModal: false,
          });
          onClose();
          navigate(`/deposit`);
        }}
        onCancel={() => {
          setModalVisible({
            ...modalVisible,
            InsBalanceModal: false,
          });
        }}
      />
      ;<div className="modal-mask"></div>
      <div className="modal-content">
        <CloseIcon onClick={onClose} />
        <div className="title">{t("CurrencyChose.Choose_Currency")}</div>
        <ul className="currency-list">
          {dataSource.map((v) => {
            const CoinName = GetCoinName(v.Type);
            const CoinPic = GetCoinPic(v.Type);
            return (
              <li
                key={v.Type}
                className={classNames("item", {
                  active: selectValues.Type === v.Type,
                })}
                onClick={() => {
                  let item = v;
                  _onSelect(item);
                }}
              >
                <Radio checked={selectValues.Type === v.Type} />
                <span className={classNames("icon", CoinPic)}></span>
                <span className="name">{CoinName}</span>
                {v.Coin && <span className="amount">{v.Coin}</span>}
              </li>
            );
          })}
        </ul>
        <div className="confirm-btn" onClick={onConfirm}>
          {t("CurrencyChose.Confirm")}
        </div>
      </div>
    </div>
  );
};

export default CurrencyChose;
